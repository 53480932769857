@tailwind base;
@tailwind components;
@tailwind utilities;
/* index.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.carousel-container {
  overflow: hidden; /* Hide overflow content */
  max-height: 500px; /* Set a maximum height */
  position: relative; /* Ensure the container is positioned correctly */
}

  .Walkingimg {
    position: absolute;
    top: 0;
    opacity: 0.2;
    height: 72VH;
    width: 100%;
    background: url('./Components/Walkingimg.png') center/cover no-repeat;
    z-index: -2;

  }
  .txt-grad {
    background-image: linear-gradient(to right, #be95c4, #e0b1cb); /* Example gradient colors */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; /* Makes the text color transparent */
  }
  .nailcuttingimg {
    position:relative;
    background: url('./pics/podservices.png') center/cover no-repeat;
  }
  .nailcuttingimg2 {
    background: url('./pics/PODSERVICES2.png') center/cover no-repeat;
  }

  .aboutusclinic {
    background: url('./pics/aboutclinic.png') center/cover no-repeat;
  }
  .manchesterclinic {
    background: url('./pics/manchesterclinic.png') center/cover no-repeat;
  }
  .wavy-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .wavy-line path {
    fill: url(#gradient);
    mask-image: linear-gradient(to bottom, black 50%, transparent);
  }